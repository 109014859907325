<template>
  <div id="settings-layout" class="settings-layout">
    <AuthenticatedLayoutHeader />
    <div id="layout-content" class="settings-layout-content">
      <main class="settings-layout-content-main">
        <div
          v-if="grid('hidden xl:block')"
          class="border-r border-gray-200 xl:w-[264px]"
        >
          <SidebarSettings />
        </div>
        <RouterView v-slot="{ Component, route }">
          <transition name="fade" mode="out-in">
            <component :is="Component" :key="route.name" />
          </transition>
        </RouterView>
      </main>
      <AuthenticatedFooter />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDsResponsive } from "@devsalsa/vue-core";

import AuthenticatedFooter from "@/core/shared/components/Layout/AuthenticatedFooter.vue";
import AuthenticatedLayoutHeader from "@/core/shared/components/Layout/AuthenticatedLayoutHeader.vue";

import SidebarSettings from "@/modules/settings/components/SidebarSettings.vue";

defineOptions({
  name: "SettingsLayout",
});

const { grid } = useDsResponsive();
</script>

<style lang="scss" scoped>
.settings-layout {
  @apply flex lg:h-screen flex-col;

  &-content {
    @apply w-full h-full bg-gray-50 flex-1 lg:overflow-y-auto;

    &-main {
      @apply xl:container flex mx-auto flex-col xl:flex-row w-full min-h-[calc(100vh_-_426px)] sm:min-h-[calc(100vh_-_212px)];
    }
  }
}
</style>
