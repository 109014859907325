<template>
  <footer
    class="authenticated-footer"
    data-testid="authenticated-footer-background"
  >
    <div
      class="authenticated-footer-grid"
      data-testid="authenticated-footer-grid"
    >
      <div
        class="authenticated-footer-links"
        data-testid="authenticated-footer-blog-academy"
      >
        <!--External link for footer-->
        <ExternalFooterLink
          href="https://joinbrands.com/blog/"
          target="_blank"
          rel="nofollow"
          class="footer-link-blog"
        >
          {{ $t("core.shared.components.layout.footer.blog") }}
        </ExternalFooterLink>
        <ExternalFooterLink
          href="https://help.joinbrands.com/en/"
          target="_blank"
          rel="nofollow"
          class="footer-link-academy"
        >
          {{ $t("core.shared.components.layout.footer.academy") }}
        </ExternalFooterLink>
        <ExternalFooterLink
          href="https://help.joinbrands.com/en/collections/3414741-brands"
          target="_blank"
          rel="nofollow"
          class="footer-link-faq ds-link-no-underline"
        >
          {{ $t("core.shared.components.layout.footer.faq") }}
        </ExternalFooterLink>
        <DsButton
          theme-display="link"
          class="footer-link-contact ds-link-no-underline"
          data-testid="contact-support-footer-link"
          @click="onOpenModal"
        >
          {{ $t("core.shared.components.layout.footer.contact") }}
        </DsButton>
        <DsButton
          v-if="false"
          theme-display="link"
          class="footer-link-news ds-link-no-underline space-x-1"
          data-testid="news-footer-link"
        >
          <span>{{ $t("core.shared.components.layout.footer.news") }}</span>
        </DsButton>
      </div>
      <div
        class="authenticated-footer-info"
        data-testid="authenticated-footer-info"
      >
        <div class="version">© {{ date }} JoinBrands.com {{ version }}</div>
      </div>
      <div
        class="authenticated-footer-terms"
        data-testid="authenticated-footer-terms"
      >
        <div class="policy-and-terms">
          <ExternalFooterLink
            :href="'https://joinbrands.com/privacy'"
            target="_blank"
            class="policy-link"
            rel="nofollow"
          >
            {{ $t("common.privacy") }}
          </ExternalFooterLink>
          <ExternalFooterLink
            :href="'https://joinbrands.com/terms'"
            target="_blank"
            class="terms-link"
            rel="nofollow"
          >
            {{ $t("common.terms") }}
          </ExternalFooterLink>
        </div>
      </div>
      <div
        class="authenticated-footer-social"
        data-testid="authenticated-footer-social-media"
      >
        <div class="social-links">
          <div class="social-links-list">
            <ExternalFooterLink
              v-for="(item, index) in socialLinks"
              :key="index"
              :href="item.link"
              rel="nofollow"
              target="_blank"
              class="inline-block"
            >
              <img :src="item.icon" :alt="item.name" />
            </ExternalFooterLink>
          </div>
          <div v-if="grid('md:block hidden')">
            <span class="marketplace-language">
              {{ $t("core.shared.components.layout.footer.language") }}
            </span>
            <span class="marketplace-currency">
              {{ currencyLabel }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="grid('block md:hidden')"
        class="authenticated-footer-marketplace"
        data-testid="authenticated-footer-marketplace"
      >
        <div class="marketplace-language-currency">
          <div class="marketplace px-4">
            <span class="marketplace-language">
              {{ $t("core.shared.components.layout.footer.language") }}
            </span>
            <span class="marketplace-currency">
              {{ currencyLabel }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <ContactSupportFormModal />
  </footer>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { DsButton, DsModalHandler, useDsResponsive } from "@devsalsa/vue-core";

import ExternalFooterLink from "@/core/shared/components/Link/ExternalFooterLink.vue";

import { type IntlNumberFormat, useI18n } from "vue-i18n";

import ContactSupportFormModal from "@/modules/contact/components/ContactSupportFormModal.vue";

import facebook from "@/assets/img/social-media/facebook-gray.svg";
import instagram from "@/assets/img/social-media/instagram-gray.svg";
import tiktok from "@/assets/img/social-media/tiktok-gray.svg";
import youtube from "@/assets/img/social-media/youtube-gray.svg";

defineOptions({
  name: "AuthenticatedFooter",
});

const { locale, getNumberFormat } = useI18n();
const { grid } = useDsResponsive();

const date = new Date().getFullYear();
const version = `v${import.meta.env.PACKAGE_VERSION}`;
const socialLinks = [
  {
    name: "Instagram",
    icon: instagram,
    link: "https://www.instagram.com/joinbrandsnow/",
  },
  {
    name: "Facebook",
    icon: facebook,
    link: "https://www.facebook.com/joinbrands",
  },
  {
    name: "TikTok",
    icon: tiktok,
    link: "https://www.tiktok.com/@joinbrands",
  },
  {
    name: "YouTube",
    icon: youtube,
    link: "https://www.youtube.com/@joinbrands",
  },
];

const currencyLabel = computed((): string => {
  return String(
    getNumberFormat<IntlNumberFormat>(locale.value).currency.currency
  );
});

function onOpenModal(): void {
  DsModalHandler.open("contact-support-modal");
}
</script>

<style lang="scss" scoped>
.authenticated-footer {
  @apply h-auto w-full border-gray-200 bg-gray-50 sm:border-t;

  &-grid {
    @apply grid grid-flow-row-dense grid-cols-1 md:grid-cols-5 md:grid-rows-2 xl:container xl:mx-auto;
  }

  &-links {
    @apply order-1 row-span-4 border-b border-gray-200 py-6 pl-4 md:order-1 md:col-span-3 md:row-span-1 md:ml-4 md:pl-0 xl:ml-6;

    .footer-link-blog {
      @apply block text-sm font-medium text-gray-500 md:inline-block;
    }

    .footer-link-academy {
      @apply mt-2 block text-sm font-medium text-gray-500 md:mt-0 md:ml-6 md:inline-block;
    }

    .footer-link-faq {
      @apply mt-2 block text-sm font-medium text-gray-500 md:mt-0 md:ml-6 md:inline-block;
    }

    .footer-link-contact {
      @apply mt-2 block text-sm font-medium text-gray-500 md:mt-0 md:ml-6 md:inline-block;
    }

    .footer-link-news {
      @apply mt-2 block text-sm font-medium text-gray-500 md:mt-0 md:ml-6 md:inline-block;
    }
  }

  &-info {
    @apply order-4 row-span-1 md:order-3 md:col-span-3;

    .version {
      @apply mb-6 ml-4 flex h-6 items-center text-sm font-normal text-gray-400 md:my-6 md:mb-0 xl:ml-6;
    }
  }

  &-terms {
    @apply order-2 row-span-2 border-b border-gray-200 md:order-2 md:col-span-2 md:row-span-1 md:mr-4 xl:mr-6;

    .policy-and-terms {
      @apply my-6 ml-4 justify-end md:ml-0 md:flex;

      .policy-link {
        @apply mr-6 block pb-2 text-sm font-medium text-gray-500 md:py-0.5 md:inline-block md:pb-0;
      }

      .terms-link {
        @apply text-sm font-medium text-gray-500 md:py-0.5;
      }
    }
  }

  &-social {
    @apply order-3 row-span-2 md:col-span-2 md:row-span-1 md:mr-4 xl:mr-6;

    .social-links {
      @apply my-6 mr-4 md:mr-0 justify-end items-center md:flex;

      img {
        @apply h-5 w-5;
      }

      &-list {
        @apply flex flex-wrap gap-4 px-4 md:px-0 mr-8;
      }

      .marketplace-language {
        @apply text-sm text-gray-400 xl:mr-6 mr-4;
      }

      .marketplace-currency {
        @apply text-sm text-gray-400;
      }
    }
  }

  &-marketplace {
    @apply order-3;

    .marketplace-language-currency {
      @apply mb-6;

      .marketplace-language {
        @apply text-sm text-gray-400 mr-4;
      }

      .marketplace-currency {
        @apply text-sm text-gray-400;
      }
    }
  }
}
</style>
